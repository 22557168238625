// @flow
import React, {
  useEffect,
  type StatelessFunctionalComponent,
} from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table/actions";
import { GetApp } from "@mui/icons-material";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import type { ItemData, TotalData } from "../../services/dashboardApi";
import { setQueryFilter } from "../../helpers/queryFilter";
import TransactionReportFilters from "./TransactionReportFilters";
import TransactionReportTable from "./TransactionReportTable";

type Props = {|
  data: ItemData[],
  totalData: TotalData,
  page: number,
  total: number,
  pageSize: number,
  filters: Filters,
  loading: boolean,
  onChangeTablePage: (string, number) => mixed,
  onChangeTableFilters: (string, Filters) => mixed,
  onGetData: () => mixed,
  onDownloadTransactionReport: () => mixed,
  departmentsDropdown: DropDownObjItemType[],
  countriesDropdown: DropDownObjItemType[],
  customersDropdown: DropDownObjItemType[],
  platformsDropdown: DropDownObjItemType[],
  agesDropdown: DropDownObjItemType[],
  onGetCountriesDropdownData: () => mixed,
  onGetPlatformsDropdownData: () => mixed,
  onGetAgesDropdownData: () => mixed,
  setIsAdvancedFilterEnabled: (boolean) => mixed,
  isAdvancedFilterEnabled: boolean,
  fields: string[],
|}

const TransactionReport: StatelessFunctionalComponent<Props> = ({
  data,
  totalData,
  page,
  total,
  pageSize,
  filters,
  loading,
  onChangeTablePage,
  onChangeTableFilters,
  onGetData,
  onDownloadTransactionReport,
  departmentsDropdown,
  countriesDropdown,
  customersDropdown,
  platformsDropdown,
  agesDropdown,
  onGetCountriesDropdownData,
  onGetPlatformsDropdownData,
  onGetAgesDropdownData,
  setIsAdvancedFilterEnabled,
  isAdvancedFilterEnabled,
  fields,
}: Props) => {
  useEffect(() => {
    if (data.length === 0) {
      onGetData();
    }
  }, []);

  useEffect(() => {
    onGetCountriesDropdownData();
    onGetPlatformsDropdownData();
    onGetAgesDropdownData();
  }, []);

  useEffect(() => {
    setQueryFilter(filters);
  }, [filters]);

  const isShowData: boolean = data.length > 0 && !loading;
  const isShowNoDataMessage: boolean = data.length === 0 && !loading;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box width={1} pb={2} display="flex" justifyContent="flex-start">
          <Box>
            <Button
              variant="contained"
              color="secondary"
              name="download"
              disabled={loading}
              startIcon={<GetApp />}
              onClick={onDownloadTransactionReport}
            >
              Export Report
            </Button>
          </Box>
        </Box>
        <TransactionReportFilters
          filters={filters}
          loading={loading}
          onChangeTableFilters={onChangeTableFilters}
          onGetData={onGetData}
          departmentsDropdown={departmentsDropdown}
          countriesDropdown={countriesDropdown}
          customersDropdown={customersDropdown}
          platformsDropdown={platformsDropdown}
          agesDropdown={agesDropdown}
          setIsAdvancedFilterEnabled={setIsAdvancedFilterEnabled}
          isAdvancedFilterEnabled={isAdvancedFilterEnabled}
        />
        { loading && (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
        { isShowNoDataMessage && (
          <Grid container justifyContent="center">
            <Typography>Nothing to show</Typography>
          </Grid>
        )}
        { isShowData && (
          <TransactionReportTable
            data={data}
            totalData={totalData}
            page={page}
            total={total}
            pageSize={pageSize}
            fields={fields}
            filters={filters}
            onChangeTablePage={onChangeTablePage}
            onGetData={onGetData}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TransactionReport;
