// @flow
/* eslint-disable import/max-dependencies */
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";

import {
  getTableData,
  getTableFilters,
  getTablePage,
  getTablePageSize,
  getTableTotalAmount,
  getTableTotalData,
} from "@fas/cpa-state-manager/services/selectors/table";
import getLoadingState from "@fas/cpa-state-manager/services/selectors/loading";
import {
  changeTableFilters,
  changeTablePage,
} from "@fas/cpa-state-manager/redux/actions/table";
import type {
  DispatchToProps, OwnProps, Props, StateToProps,
} from "./types/TransactionReport.types";
import TransactionReportComponent from "../../components/TransactionReport";
import { TRANSACTION_TABLE } from "../../helpers/constants";
import type { State } from "../../store";
import {
  downloadTransactionReportSaga,
  getTransactionReportDataSaga,
  setTransactionReportTableIsAdvanced,
} from "../../actions/transactionReport";
import { getDictionary } from "../../selectors/dictionaries";
import type { GetDictionarySaga } from "../../actions/dictionaries";
import { getDictionarySaga } from "../../actions/dictionaries";
import { getTransactionReportFields } from "../../selectors/transactionReport";
import { getAuthDepartments, getAuthProductCompany } from "../../services/request";
import type { DropDownObjItemType } from "../../reducers/dictionaries";

const mapStateToProps: (state: State) => StateToProps = (state) => ({
  data: getTableData(state, TRANSACTION_TABLE),
  // $FlowFixMe conflict type with lib
  totalData: getTableTotalData(state, TRANSACTION_TABLE),
  filters: getTableFilters(state, TRANSACTION_TABLE),
  // $FlowFixMe @fas/cpa-state-manager/services/selectors/loading fix type
  loading: getLoadingState(state, "getTransactionReportLoading") || getLoadingState(state, "downloadTransactionReportLoading"),
  pageSize: getTablePageSize(state, TRANSACTION_TABLE),
  page: getTablePage(state, TRANSACTION_TABLE),
  total: getTableTotalAmount(state, TRANSACTION_TABLE),
  departmentsDropdown: getAuthDepartments().map((value: string): DropDownObjItemType => ({ label: value, value })),
  countriesDropdown: getDictionary(state, "country"),
  customersDropdown: getAuthProductCompany().map((value: string): DropDownObjItemType => ({ label: value, value })),
  platformsDropdown: getDictionary(state, "platform"),
  agesDropdown: getDictionary(state, "ageGroup"),
  isAdvancedFilterEnabled: state.transactionReport.get("isAdvancedFilter"),
  fields: getTransactionReportFields(state),
});

const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => bindActionCreators({
  onDownloadTransactionReport: downloadTransactionReportSaga,
  setIsAdvancedFilterEnabled: setTransactionReportTableIsAdvanced,

  onChangeTablePage: changeTablePage,
  onChangeTableFilters: changeTableFilters,
  onGetData: getTransactionReportDataSaga,

  onGetCountriesDropdownData: (): GetDictionarySaga => getDictionarySaga("country"),
  onGetPlatformsDropdownData: (): GetDictionarySaga => getDictionarySaga("platform"),
  onGetAgesDropdownData: (): GetDictionarySaga => getDictionarySaga("ageGroup"),
}, dispatch);

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(TransactionReportComponent);
